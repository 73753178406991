import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";

function Projects() {
  const [ott, setOtt] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newProject, setNewProject] = useState({
    projectName: "",
    category: "",
    contentType: "",
    language: "",
    buyer: "",
    poster: "",
    contract: "",
    additionalContracts: "",
    synopsis: "",
    story: "",
    productionHouse: "",
    producer: "",
    director: "",
    creativeDirector: "",
    writer: "",
    dop: "",
    projectPPT: "",
    screenplay: "",
    casting: "",
    budgetSheet: "",
    tentativeBudget: 0,
    ottId: "",
  });

  // Handle input change for new project form
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewProject((prevProject) => ({
      ...prevProject,
      [name]: name === "tentativeBudget" ? parseFloat(value) || 0 : value,
    }));
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewProject((prevProject) => ({
      ...prevProject,
      [name]: files.length > 1 ? [...files] : files[0],
    }));
  };

  // Fetch projects from the API when the component loads
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/projects/user/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setProjects(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Handle adding a new project
  const handleAddProject = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Create FormData object to append both files and other project data
    const formData = new FormData();
    formData.append("category", newProject.category);
    formData.append("contentType", newProject.contentType);
    formData.append("language", newProject.language);
    formData.append("buyer", newProject.buyer);
    formData.append("projectName", newProject.projectName);
    formData.append("synopsis", newProject.synopsis);
    formData.append("story", newProject.story);
    formData.append("productionHouse", newProject.productionHouse);
    formData.append("producer", newProject.producer);
    formData.append("director", newProject.director);
    formData.append("creativeDirector", newProject.creativeDirector);
    formData.append("writer", newProject.writer);
    formData.append("dop", newProject.dop);
    formData.append("casting", newProject.casting);
    formData.append("tentativeBudget", newProject.tentativeBudget);
    formData.append("ottId", newProject.ottId);

    if (newProject.poster) {
      formData.append("poster", newProject.poster);
    }
    if (newProject.contract) {
      formData.append("contract", newProject.contract);
    }
    if (newProject.projectPPT) {
      formData.append("projectPPT", newProject.projectPPT);
    }
    if (newProject.screenplay) {
      formData.append("screenplay", newProject.screenplay);
    }
    if (newProject.budgetSheet) {
      formData.append("budgetSheet", newProject.budgetSheet);
    }
    if (newProject.additionalContracts) {
      formData.append("additionalContracts", newProject.additionalContracts);
    }

    try {
      const response = await axios.post(
        "https://fundoramaa.cloud/api/v1/projects/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.data) {
        setProjects((prevProjects) => [...prevProjects, response.data.data]);
        resetNewProject();
        setIsSubmitting(false);
        const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
      } else {
        console.error("Unexpected response format:", response.data);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error adding project:", error);
      setIsSubmitting(false);
    }
  };

  const resetNewProject = () => {
    setNewProject({
      projectName: "",
      category: "",
      contentType: "",
      language: "",
      buyer: "",
      poster: "",
      contract: "",
      additionalContracts: "",
      synopsis: "",
      story: "",
      productionHouse: "",
      producer: "",
      director: "",
      creativeDirector: "",
      writer: "",
      dop: "",
      projectPPT: "",
      screenplay: "",
      budgetSheet: "",
      casting: "",
      tentativeBudget: 0,
      ottId: "",
    });
  };

  // Fetch OTT from the API
  useEffect(() => {
    const fetchOtt = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/ott/"
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setOtt(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching otts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOtt();
  }, []);

  return (
    <>
      <Header />

      <div class="modal fade" id="addModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content rounded-4">
            <div className="end-0 position-absolute z-1">
              <button
                type="button"
                class="border-0 bg-transparent m-n3"
                data-bs-dismiss="modal"
              >
                <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleAddProject}>
                <div className="card-body px-lg-6 px-4 py-3">
                  <h2 className="lh-1 mb-4">Add project details</h2>
                  <div>
                    <div className="mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.0 Category
                      </p>
                    </div>
                    <div>
                      <label>Select category</label>
                      <select
                        className="form-control mt-1"
                        name="category"
                        value={newProject.category}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="new project">New Project</option>
                        <option value="gap funding">Gap Funding</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.1 Content Type Selection
                      </p>
                    </div>
                    <div>
                      <label>Select your type of content</label>
                      <select
                        className="form-control mt-1"
                        name="contentType"
                        value={newProject.contentType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="tvshow">TV Show</option>
                        <option value="webseries">Web Series</option>
                        <option value="movie">Movie</option>
                        <option value="shortfilm">Short Film</option>
                        <option value="regional">Regional</option>
                      </select>
                    </div>
                    <div className="mt-3">
                      <label>Select language</label>
                      <select
                        className="form-control mt-1"
                        name="language"
                        value={newProject.language}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="hindi">Hindi</option>
                        <option value="bengali">Bengali</option>
                        <option value="marathi">Marathi</option>
                        <option value="telugu">Telugu</option>
                        <option value="tamil">Tamil</option>
                        <option value="gujarati">Gujarati</option>
                        <option value="bhojpuri">Bhojpuri</option>
                        <option value="rajasthani">Rajasthani</option>
                        <option value="chhattisgarhi">Chhattisgarhi</option>
                        <option value="magahi">Magahi</option>
                        <option value="haryanvi">Haryanvi</option>
                        <option value="khortha/khotta">Khortha/Khotta</option>
                        <option value="marwari">Marwari</option>
                        <option value="bundeli/bundel khandi">
                          Bundeli/Bundel khandi
                        </option>
                        <option value="malvi">Malvi</option>
                        <option value="sadan/sadri">Sadan/Sadri</option>
                        <option value="mewari">Mewari</option>
                        <option value="awadhi">Awadhi</option>
                        <option value="lamani/lambadi">Lamani/Lambadi</option>
                        <option value="konkani">Konkani</option>
                        <option value="kurukh/oraon">Kurukh/Oraon</option>
                        <option value="tulu">Tulu</option>
                        <option value="manipuri">Manipuri</option>
                        <option value="sindhi">Sindhi</option>
                        <option value="pahari">Pahari</option>
                        <option value="hara/harauti">Hara/Harauti</option>
                        <option value="bagheli/baghel khandi">
                          Bagheli/Baghel Khandi
                        </option>
                        <option value="garhwali">Garhwali</option>
                        <option value="nimadi">Nimadi</option>
                        <option value="surjapuri">Surjapuri</option>
                        <option value="kumauni">Kumauni</option>
                        <option value="surgujia">Surgujia</option>
                        <option value="banjari">Banjari</option>
                        <option value="brajbhasha">Brajbhasha</option>
                        <option value="dhundhari">Dhundhari</option>
                        <option value="gojri/gujjari/gujar">
                          Gojri/Gujjari/Gujar
                        </option>
                        <option value="kangri">Kangri</option>
                        <option value="ahirani">Ahirani</option>
                        <option value="sambalpuri">Sambalpuri</option>
                        <option value="bagri">Bagri</option>
                        <option value="kachchhi">Kachchhi</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.2 Project Details
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Enter your rights buyer</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="buyer"
                        value={newProject.buyer}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Enter your project name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="projectName"
                        value={newProject.projectName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Upload Poster</label>
                      <input
                        type="file"
                        name="poster"
                        onChange={handleFileChange}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="mt-2">
                      <label>Upload Contract</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="contract"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.3 Multiple Contracts Option
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Upload all contracts</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="additionalContracts"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.4 New Project Submission
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Synopsis / One-Line Story</label>
                      <textarea
                        type="text"
                        rows="4"
                        className="form-control mt-1"
                        name="synopsis"
                        value={newProject.synopsis}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <label>Story</label>
                      <textarea
                        type="text"
                        rows="4"
                        className="form-control mt-1"
                        name="story"
                        value={newProject.story}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <label>Production House Name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="productionHouse"
                        value={newProject.productionHouse}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Producer</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="producer"
                        value={newProject.producer}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="director"
                        value={newProject.director}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Creative Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="creativeDirector"
                        value={newProject.creativeDirector}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Writer</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="writer"
                        value={newProject.writer}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director of Photography (DOP)</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="dop"
                        value={newProject.dop}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Project PPT Look and Feel</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        name="projectPPT"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Story Narration (Screenplay with Dialogue)</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        name="screenplay"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Casting</label>
                      <textarea
                        type="text"
                        rows="4"
                        className="form-control mt-1"
                        name="casting"
                        value={newProject.casting}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <label>Tentative Budget</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="tentativeBudget"
                        value={newProject.tentativeBudget}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Budget Sheet</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        name="budgetSheet"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Select OTT</label>
                      <select
                        className="form-control mt-1"
                        name="ottId"
                        value={newProject.ottId}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        {ott.map((ott) => (
                          <option value={ott._id}>{ott.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg mt-5 w-100 rounded-3 fs-5 text-white"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting ..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="d-none"
                  ></button>
                </div>
              </form>
              <div className="card-footer rounded-4 px-6 pb-4">
                <p className="mb-0 text-center">
                  By submitting you accept our{" "}
                  <a href="#" className="text-inherit fw-semibold">
                    <u>Policies</u>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <a
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            class="btn btn-dark px-4 rounded-3 me-5"
          >
            <span className="mx-2">
              Add Project <i className="bi bi-file-post ms-2"></i>
            </span>
          </a>

          <div className="d-flex flex-column">
            <span className="fs-7 px-5 py-6 mb-1 rounded-pill text-center text-black border border-secondary bg-white cursor-pointer">
              Pending (2)
            </span>
            <span className="fs-7 px-5 py-6 rounded-pill text-center text-black border bg-white border-secondary cursor-pointer">
              Approved (0)
            </span>
          </div>
        </div>
      </div>

      <section className="pb-7 mt-5 mb-3">
        <div className="container">
          {loading ? (
            <div class="spinner-border" role="status"></div>
          ) : (
            <div class="row g-2">
              {projects.map((project) => (
                <div class="col-lg-4 col-12">
                  <div className="card rounded-4 card-bordered card-lift">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          src={project.poster}
                          alt="Poster"
                          className="w-100 rounded-top-4 object-fit-cover object-position-top"
                          height="200"
                        />
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column justify-content-between p-4 h-100">
                          <div>
                            <div className="d-flex flex-column">
                              <div>
                                <span
                                  class={`badge px-4 ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <h3 className="mb-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${project._id}`}
                                    className="text-reset cursor-pointer"
                                  >
                                    {project.projectName}
                                  </a>
                                </h3>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-patch-check-fill text-success"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                  </svg>
                                </span>
                              </div>
                              <p className="m-0 text-capitalize">
                                {project.category}
                              </p>
                            </div>
                            <div className="mt-3 mb-4">
                              <div class="mb-2 mb-md-0">
                                <span class="me-4">
                                  <i class="bi bi-camera-reels"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.contentType}
                                  </span>
                                </span>
                                <span class="me-4">
                                  <i class="bi bi-chat-square-quote"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.language}
                                  </span>
                                </span>
                                <br />
                                <span class="me-4">
                                  <i class="bi bi-tv"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.ottId.name}
                                  </span>
                                </span>
                                <div>
                                  <i class="bi bi-calendar-week me-1"></i>{" "}
                                  <span className="text-uppercase">
                                    {new Date(project.createdAt).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="progress mb-3" role="progressbar">
                              <div class="progress-bar w-15 rounded-pill"></div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <span>Goal</span>
                                <div className="d-flex flex-row gap-1 align-items-center">
                                  <h4 className="mb-0">
                                    {new Intl.NumberFormat("en-IN").format(
                                      project.tentativeBudget
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${project._id}`}
                                  className="btn btn-outline-primary px-4 rounded-3"
                                >
                                  More Details
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id={project._id}
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content rounded-4">
                        <div className="end-0 position-absolute z-1">
                          <button
                            type="button"
                            class="border-0 bg-transparent m-n3"
                            data-bs-dismiss="modal"
                          >
                            <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div className="card-body px-lg-6 px-4 py-3">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <span
                                  class={`badge ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                                <h3 class="mb-1 fs-4">
                                  <span class="text-inherit text-capitalize">
                                    {project.projectName}
                                  </span>
                                </h3>
                                <div>
                                  <span className="text-capitalize">
                                    {project.category}
                                  </span>
                                </div>
                                <div>
                                  <span className="text-dark">
                                    {project.ottId.name}
                                  </span>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.1 Content Type Selection
                                  </p>
                                  <ul>
                                    <li>
                                      Type of content -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentType}
                                      </span>
                                    </li>
                                    <li>
                                      Language -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.language}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.2 Project Details
                                  </p>
                                  <ul>
                                    <li>
                                      Rights Buyer -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.buyer}
                                      </span>
                                    </li>
                                    <li>
                                      Project Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.projectName}
                                      </span>
                                    </li>
                                    <li>
                                      Poster -{" "}
                                      <a
                                        href={project.poster}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Poster
                                      </a>
                                    </li>
                                    <li>
                                      Contract -{" "}
                                      <a
                                        href={project.contract}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Contract
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <img
                                  src={project.poster}
                                  alt=""
                                  class="icon-shape border rounded-4 object-fit-cover w-100"
                                  height="350"
                                />
                              </div>
                            </div>
                            <div className="mt-5">
                              <div className="mt-4">
                                <p className="text-dark">
                                  1.3 Multiple Contracts Option
                                </p>
                                <ul>
                                  <li>
                                    All Contracts -{" "}
                                    <a
                                      href={project.additionalContracts}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View All Contracts
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="mt-4">
                                <p className="text-dark">
                                  1.4 New Project Submission
                                </p>
                                <ul>
                                  <li>
                                    Synopsis / One-Line Story -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.synopsis}
                                    </span>
                                  </li>
                                  <li>
                                    Story -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.story}
                                    </span>
                                  </li>
                                  <li>
                                    Production House Name -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.productionHouse}
                                    </span>
                                  </li>
                                  <li>
                                    Producer -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.producer}
                                    </span>
                                  </li>
                                  <li>
                                    Director -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.director}
                                    </span>
                                  </li>
                                  <li>
                                    Creative Director -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.creativeDirector}
                                    </span>
                                  </li>
                                  <li>
                                    Writer -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.writer}
                                    </span>
                                  </li>
                                  <li>
                                    Director of Photography (DOP) -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.dop}
                                    </span>
                                  </li>
                                  <li>
                                    Project PPT Look and Feel -{" "}
                                    <a
                                      href={project.projectPPT}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View PPT
                                    </a>
                                  </li>
                                  <li>
                                    Story Narration (Screenplay with Dialogue) -{" "}
                                    <a
                                      href={project.screenplay}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View File
                                    </a>
                                  </li>
                                  <li>
                                    Casting -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.casting}
                                    </span>
                                  </li>
                                  <li>
                                    Tentative Budget (INR) -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.tentativeBudget}
                                    </span>
                                  </li>
                                  <li>
                                    Budget Sheet -{" "}
                                    <a
                                      href={project.budgetSheet}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View Budget Sheet
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="mt-5">
                              <h3>OTT Updates</h3>
                              <span
                                class={`badge ${
                                  project.ottUpdates === "approved"
                                    ? "bg-success-soft"
                                    : "bg-danger-soft"
                                } mb-2 text-capitalize`}
                              >
                                {project.ottUpdates}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Projects;
