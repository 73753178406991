import { Link, NavLink, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/");
  };

  return (
    <>
      <nav className="sticky-top navbar navbar-expand-lg py-1">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="/assets/images/logo.png" width="60" alt="" />
          </Link>
          <div className="ms-auto d-flex align-items-center order-lg-3">
            <div class="d-none d-md-block">
              <button
                onClick={handleLogout}
                class="btn btn-primary px-5 rounded-3 text-white"
              >
                Logout
              </button>
            </div>
          </div>
          <div>
            <button
              className="navbar-toggler collapsed ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar mt-0" />
              <span className="icon-bar middle-bar" />
              <span className="icon-bar bottom-bar" />
            </button>
          </div>
          <div className="collapse navbar-collapse ps-lg-5" id="navbar-default">
            <ul className="navbar-nav mt-3 mt-lg-0">
              <li className="nav-item">
                <NavLink to="/admin/dashboard" className="nav-link">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/projects" className="nav-link">
                  Projects
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/unreleased" className="nav-link">
                  Unreleased
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/released" className="nav-link">
                  Released
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
