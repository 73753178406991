import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";

function Released() {
  const [ott, setOtt] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
  const [ready, setReady] = useState([]);
  const [loading, setLoading] = useState(true);
  const [released, setReleased] = useState({
    contentType: "",
    language: [],
    projectName: "",
    lease: "",
    exclusiveRights: "",
    nonExclusiveRights: "",
    poster: "",
    contentDescription: "",
    uploadTrailer: "",
    productionHouseName: "",
    producer_investor: "",
    director: "",
    creativeDirector: "",
    writer: "",
    dop: "",
    projectDeclaration: "",
    contract_agreement: "",
    noticeLegal: "",
    budget: 0,
    ottId: "",
  });

  // Handle input change for new project form
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setReleased((prevProject) => ({
      ...prevProject,
      [name]: name === "budget" ? parseFloat(value) || 0 : value,
    }));
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setReleased((prevProject) => ({
      ...prevProject,
      [name]: files.length > 1 ? [...files] : files[0],
    }));
  };

  // Fetch projects from the API when the component loads
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/content/user/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setReady(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Handle form input changes, including checkboxes and text fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setReleased((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value] // Add the checkbox value if checked
          : prevData[name].filter((item) => item !== value), // Remove it if unchecked
      }));
    } else {
      setReleased((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleReadyToRelease = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Create FormData object to append both files and other project data
    const formData = new FormData();

    // Append non-file fields
    formData.append("contentType", released.contentType);
    formData.append("language", released.language);
    formData.append("projectName", released.projectName);
    formData.append("lease", released.lease);
    formData.append("exclusiveRights", released.exclusiveRights);
    formData.append("nonExclusiveRights", released.nonExclusiveRights);
    formData.append("contentDescription", released.contentDescription);
    formData.append("productionHouseName", released.productionHouseName);
    formData.append("producer_investor", released.producer_investor);
    formData.append("director", released.director);
    formData.append("creativeDirector", released.creativeDirector);
    formData.append("dop", released.dop);
    formData.append("budget", released.budget);
    formData.append("ottId", released.ottId);

    // Append files to FormData
    if (released.poster) {
      formData.append("poster", released.poster);
    }
    if (released.uploadTrailer) {
      formData.append("uploadTrailer", released.uploadTrailer);
    }
    if (released.projectDeclaration) {
      formData.append("projectDeclaration", released.projectDeclaration);
    }
    if (released.contract_agreement) {
      formData.append("contract_agreement", released.contract_agreement);
    }
    if (released.noticeLegal) {
      formData.append("noticeLegal", released.noticeLegal);
    }

    try {
      const response = await axios.post(
        "https://fundoramaa.cloud/api/v1/content/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.data) {
        setReady((prevProjects) => [...prevProjects, response.data.data]);
        resetNewProject();
        setIsSubmitting(false);
        const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
      } else {
        console.error("Unexpected response format:", response.data);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error adding project:", error);
      setIsSubmitting(false);
    }
  };

  const resetNewProject = () => {
    setReleased({
      contentType: "",
      language: [],
      projectName: "",
      lease: "",
      exclusiveRights: "",
      nonExclusiveRights: "",
      poster: "",
      contentDescription: "",
      uploadTrailer: "",
      productionHouseName: "",
      producer_investor: "",
      director: "",
      creativeDirector: "",
      writer: "",
      dop: "",
      projectDeclaration: "",
      contract_agreement: "",
      noticeLegal: "",
      budget: 0,
      ottId: "",
    });
  };

  // Fetch OTT from the API
  useEffect(() => {
    const fetchOtt = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/ott/"
        );
        if (response.data && Array.isArray(response.data.data)) {
          setOtt(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
          setOtt([]);
        }
      } catch (error) {
        console.error("Error fetching otts:", error);
        setOtt([]);
      } finally {
        setLoading(false);
      }
    };
    fetchOtt();
  }, []);

  return (
    <>
      <Header />

      <div class="modal fade" id="addModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content rounded-4">
            <div className="end-0 position-absolute z-1">
              <button
                type="button"
                class="border-0 bg-transparent m-n3"
                data-bs-dismiss="modal"
              >
                <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleReadyToRelease}>
                <div className="card-body px-lg-6 px-4 py-3">
                  <h2 className="lh-1 mb-4">Add Details</h2>
                  <div>
                    <div className="mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.1 Content Type Selection
                      </p>
                    </div>
                    <div>
                      <label>Select your type of content</label>
                      <select
                        className="form-control mt-1"
                        name="contentType"
                        value={released.contentType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="tvshow">TV Show</option>
                        <option value="webseries">Web Series</option>
                        <option value="movie">Movie</option>
                        <option value="shortfilm">Short Film</option>
                        <option value="regional">Regional</option>
                      </select>
                    </div>
                    <div className="mt-3">
                      <label>Select language</label>
                      <div className="row mt-1">
                        {[
                          "hindi",
                          "bengali",
                          "marathi",
                          "telugu",
                          "tamil",
                          "gujarati",
                          "bhojpuri",
                          "rajasthani",
                          "chhattisgarhi",
                          "magahi",
                          "haryanvi",
                          "khortha/khotta",
                          "marwari",
                          "bundeli/bundel khandi",
                          "malvi",
                          "sadan/sadri",
                          "mewari",
                          "awadhi",
                          "lamani/lambadi",
                          "konkani",
                          "kurukh/oraon",
                          "tulu",
                          "manipuri",
                          "sindhi",
                          "pahari",
                          "hara/harauti",
                          "bagheli/baghel khandi",
                          "garhwali",
                          "nimadi",
                          "surjapuri",
                          "kumauni",
                          "surgujia",
                          "banjari",
                          "brajbhasha",
                          "dhundhari",
                          "gojri/gujjari/gujar",
                          "kangri",
                          "ahirani",
                          "sambalpuri",
                          "bagri",
                          "kachchhi",
                        ].map((option, id) => (
                          <div className="col-4" key={id}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`language${id}`}
                                name="language"
                                value={option}
                                checked={released.language.includes(option)}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`language${id}`}
                              >
                                {option}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.2 Project Details
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Enter your project name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="projectName"
                        value={released.projectName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Select Lease</label>
                      <select
                        className="form-control mt-1"
                        name="lease"
                        value={released.lease}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1 Year">1 Year</option>
                        <option value="2 Years">2 Years</option>
                        <option value="3 Years">3 Years</option>
                        <option value="4 Years">4 Years</option>
                        <option value="5 Years">5 Years</option>
                        <option value="6 Years">6 Years</option>
                        <option value="7 Years">7 Years</option>
                        <option value="8 Years">8 Years</option>
                        <option value="9 Years">9 Years</option>
                        <option value="10 Years">10 Years</option>
                      </select>
                    </div>
                    <div className="mt-2">
                      <label>Select Exclusive Rights</label>
                      <select
                        className="form-control mt-1"
                        name="exclusiveRights"
                        value={released.exclusiveRights}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1 Year">1 Year</option>
                        <option value="2 Years">2 Years</option>
                        <option value="3 Years">3 Years</option>
                        <option value="4 Years">4 Years</option>
                        <option value="5 Years">5 Years</option>
                        <option value="6 Years">6 Years</option>
                        <option value="7 Years">7 Years</option>
                        <option value="8 Years">8 Years</option>
                        <option value="9 Years">9 Years</option>
                        <option value="10 Years">10 Years</option>
                      </select>
                    </div>
                    <div className="mt-2">
                      <label>Select Non-Exclusive Rights</label>
                      <select
                        className="form-control mt-1"
                        name="nonExclusiveRights"
                        value={released.nonExclusiveRights}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1 Year">1 Year</option>
                        <option value="2 Years">2 Years</option>
                        <option value="3 Years">3 Years</option>
                        <option value="4 Years">4 Years</option>
                        <option value="5 Years">5 Years</option>
                        <option value="6 Years">6 Years</option>
                        <option value="7 Years">7 Years</option>
                        <option value="8 Years">8 Years</option>
                        <option value="9 Years">9 Years</option>
                        <option value="10 Years">10 Years</option>
                      </select>
                    </div>
                    <div className="mt-2">
                      <label>Upload Poster</label>
                      <input
                        type="file"
                        name="poster"
                        onChange={handleFileChange}
                        className="form-control mt-1"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.3 Form Fields for Content Submission
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Content Descriptions</label>
                      <textarea
                        rows="5"
                        className="form-control mt-1"
                        name="contentDescription"
                        value={released.contentDescription}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <label>Upload Trailer</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="uploadTrailer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Production House Name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="productionHouseName"
                        value={released.productionHouseName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Producer / Investor</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="producer_investor"
                        value={released.producer_investor}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="director"
                        value={released.director}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Creative Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="creativeDirector"
                        value={released.creativeDirector}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Writer</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="writer"
                        value={released.writer}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director of Photography (DOP)</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="dop"
                        value={released.dop}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Project Declaration</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="projectDeclaration"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Upload Contract / Agreement</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="contract_agreement"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Notice & Legal</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="noticeLegal"
                        onChange={handleFileChange}
                        required
                      ></input>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        Project Declaration
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Budget</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="budget"
                        value={released.budget}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Select OTT</label>
                      <select
                        className="form-control mt-1"
                        name="ottId"
                        value={released.ottId}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        {ott.map((ott) => (
                          <option value={ott._id}>{ott.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg mt-5 w-100 rounded-3 fs-5 text-white"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting ..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="d-none"
                  ></button>
                </div>
              </form>
              <div className="card-footer rounded-4 px-6 pb-4">
                <p className="mb-0 text-center">
                  By submitting you accept our{" "}
                  <a href="#" className="text-inherit fw-semibold">
                    <u>Policies</u>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <a
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            class="btn btn-dark px-4 rounded-3 me-5"
          >
            <span className="mx-2">
              Add Project <i className="bi bi-file-post ms-2"></i>
            </span>
          </a>

          <div className="d-flex flex-column">
            <span className="fs-7 px-5 py-6 mb-1 rounded-pill text-center text-black border border-secondary bg-white cursor-pointer">
              Pending (2)
            </span>
            <span className="fs-7 px-5 py-6 rounded-pill text-center text-black border bg-white border-secondary cursor-pointer">
              Approved (0)
            </span>
          </div>
        </div>
      </div>

      <section className="pb-7 mt-5 mb-3">
        <div className="container">
          {loading ? (
            <div class="spinner-border" role="status"></div>
          ) : (
            <div class="row g-2">
              {ready.map((project) => (
                <div class="col-lg-4 col-12">
                  <div className="card rounded-4 card-bordered card-lift">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          src={project.poster}
                          alt="mentor 1"
                          className="w-100 rounded-top-4 object-fit-cover object-position-top"
                          height="200"
                        />
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column justify-content-between p-4 h-100">
                          <div>
                            <div className="d-flex flex-column">
                              <div>
                                <span
                                  class={`badge px-4 ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <h3 className="mb-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${project._id}`}
                                    className="text-reset cursor-pointer"
                                  >
                                    {project.projectName}
                                  </a>
                                </h3>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-patch-check-fill text-success"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 mb-4">
                              <div class="mb-2 mb-md-0">
                                <span class="me-4">
                                  <i class="bi bi-camera-reels"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.contentType}
                                  </span>
                                </span>
                                <span class="me-4">
                                  <i class="bi bi-chat-square-quote"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.language.join(", ")}
                                  </span>
                                </span>
                                <br />
                                <span class="me-4">
                                  <i class="bi bi-tv"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.ottId.name}
                                  </span>
                                </span>
                                <div>
                                  <i class="bi bi-calendar-week me-1"></i>{" "}
                                  <span className="text-uppercase">
                                    {new Date(project.createdAt).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="progress mb-3" role="progressbar">
                              <div class="progress-bar w-15 rounded-pill"></div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <span>Goal</span>
                                <div className="d-flex flex-row gap-1 align-items-center">
                                  <h4 className="mb-0">
                                    {new Intl.NumberFormat("en-IN").format(
                                      project.budget
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${project._id}`}
                                  className="btn btn-outline-primary px-4 rounded-3"
                                >
                                  More Details
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id={project._id}
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content rounded-4">
                        <div className="end-0 position-absolute z-1">
                          <button
                            type="button"
                            class="border-0 bg-transparent m-n3"
                            data-bs-dismiss="modal"
                          >
                            <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div className="card-body px-lg-6 px-4 py-3">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <span
                                  class={`badge ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                                <h3 class="mb-1 fs-4">
                                  <span class="text-inherit text-capitalize">
                                    {project.projectName}
                                  </span>
                                </h3>
                                <div>
                                  <span className="text-dark">
                                    {project.ottId.name}
                                  </span>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.1 Content Type Selection
                                  </p>
                                  <ul>
                                    <li>
                                      Type of content -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentType}
                                      </span>
                                    </li>
                                    <li>
                                      Language -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.language.join(", ")}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.2 Project Details
                                  </p>
                                  <ul>
                                    <li>
                                      Project Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.projectName}
                                      </span>
                                    </li>
                                    <li>
                                      Poster -{" "}
                                      <a
                                        href={project.poster}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Poster
                                      </a>
                                    </li>
                                    <li>
                                      Contract / Agreement -{" "}
                                      <a
                                        href={project.contract_agreement}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Contract
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.3 Form Fields for Content Submission
                                  </p>
                                  <ul>
                                    <li>
                                      Content Description -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentDescription}
                                      </span>
                                    </li>
                                    <li>
                                      Trailer -{" "}
                                      <a
                                        href={project.uploadTrailer}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Trailer
                                      </a>
                                    </li>
                                    <li>
                                      Production House Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.productionHouseName}
                                      </span>
                                    </li>
                                    <li>
                                      Producer / Investor -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.producer_investor}
                                      </span>
                                    </li>
                                    <li>
                                      Director-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.director}
                                      </span>
                                    </li>
                                    <li>
                                      Creative Director-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.creativeDirector}
                                      </span>
                                    </li>
                                    <li>
                                      Writer-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.writer}
                                      </span>
                                    </li>
                                    <li>
                                      Director of Photography (DOP)-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.dop}
                                      </span>
                                    </li>
                                    <li>
                                      Project Declaration -{" "}
                                      <a
                                        href={project.projectDeclaration}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Content Files
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="mt-4">
                                  <p className="text-dark">
                                    Project Declaration
                                  </p>
                                  <ul>
                                    <li>
                                      Budget-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.budget}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <img
                                  src={project.poster}
                                  alt=""
                                  class="icon-shape border rounded-4 object-fit-cover w-100"
                                  height="350"
                                />
                              </div>
                            </div>

                            <div className="mt-5">
                              <h3>OTT Updates</h3>
                              <span
                                class={`badge ${
                                  project.ottUpdates === "approved"
                                    ? "bg-success-soft"
                                    : "bg-danger-soft"
                                } mb-2 text-capitalize`}
                              >
                                {project.ottUpdates}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Released;
