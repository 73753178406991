import { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";

function OTTDashboard() {
  const [projects, setProjects] = useState({
    total: 0,
    newProjects: 0,
    gapFunding: 0,
  });
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState([0]);
  const [content, setContent] = useState([0]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/projects/ott/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ottToken")}`,
            },
          }
        );

        if (response.data?.data && Array.isArray(response.data.data)) {
          const projects = response.data.data;

          const newProjects = projects.filter(
            (project) => project.category === "new project"
          );

          const gapFundingProjects = projects.filter(
            (project) => project.category === "gap funding"
          );

          setProjects({
            total: projects.length,
            newProjects: newProjects.length,
            gapFunding: gapFundingProjects.length,
          });
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `https://fundoramaa.cloud/api/v1/ready-to-release/ott/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ottToken")}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setReady(response.data.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setReady([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setReady([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/content/ott/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ottToken")}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setContent(response.data.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setContent([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setContent([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  return (
    <>
      <Header />

      <section>
        <div className="container mt-7">
          <div class="row g-3">
            <div class="col-lg-3 col-md-12 col-12">
              <div class="card bg-light">
                <div class="p-4">
                  <span class="fs-6 text-uppercase fw-semibold">
                    New Project
                  </span>
                  <h2 class="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">
                    {projects.newProjects}
                  </h2>
                  <span>
                    <span>1 Pending</span>
                    <span class="badge bg-info ms-4">1&nbsp; Approved</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12 col-12">
              <div class="card bg-light">
                <div class="p-4">
                  <span class="fs-6 text-uppercase fw-semibold">
                    GAP Funding
                  </span>
                  <h2 class="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">
                    {projects.gapFunding}
                  </h2>
                  <span>
                    <span>1 Pending</span>
                    <span class="badge bg-warning ms-4">0&nbsp; Approved</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-12">
              <div class="card bg-light">
                <div class="p-4">
                  <span class="fs-6 text-uppercase fw-semibold">
                    Unreleased
                  </span>
                  <h2 class="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">
                    {ready}
                  </h2>
                  <span class="d-flex justify-content-between align-items-center">
                    <span>0 Pending</span>
                    <span class="badge bg-warning ms-2">0&nbsp; Approved</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-12">
              <div class="card bg-light">
                <div class="p-4">
                  <span class="fs-6 text-uppercase fw-semibold">Released</span>
                  <h2 class="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">
                    {content}
                  </h2>
                  <span class="d-flex justify-content-between align-items-center">
                    <span>Project</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OTTDashboard;
