import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";

function Profile() {
  const initialProfileState = {
    name: "",
    phone: "",
    occupation: "Salaried",
    participated: [],
    invoiceReason: [],
    referralCode: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountHolderName: "",
    branch: "",
    cinNo: "",
    panNo: "",
    gstNo: "",
  };

  const [profileData, setProfileData] = useState(initialProfileState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  console.log(profileData);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const userId = localStorage.getItem("userId");
        const token = localStorage.getItem("authToken");
        const response = await axios.get(
          `https://fundoramaa.cloud/api/v1/users/profile/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProfileData({
          ...initialProfileState,
          ...response.data.data,
          participated: response.data.data.participated || [],
          invoiceReason: response.data.data.invoiceReason || [],
        });
      } catch {
        setError("Failed to fetch profile data.");
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  const updateProfile = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        "https://fundoramaa.cloud/api/v1/users/profile/",
        profileData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert("Profile updated successfully!");
    } catch {
      setError("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setProfileData({ ...profileData, [name]: value });
  };

  const handleCheckboxChange = (e, groupName) => {
    const { value, checked } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [groupName]: checked
        ? [...(prev[groupName] || []), value]
        : (prev[groupName] || []).filter((item) => item !== value),
    }));
  };

  return (
    <>
      <Header />

      <section class="pt-5 mt-3 pb-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="rounded-top"></div>
              <div class="card bg-light px-5 pb-2 shadow-sm rounded-3">
                <div class="d-flex align-items-end justify-content-between py-4">
                  <div class="d-flex align-items-center ms-2">
                    <div class="lh-1">
                      <h2 class="m-0">
                        {profileData.name}{" "}
                        <img
                          src="https://geeksui.codescandy.com/geeks/assets/images/svg/checked-mark.svg"
                          alt="checked"
                          height="30"
                          width="30"
                        />
                      </h2>
                      <p class="mb-0 d-block">{profileData.occupation}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-7">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mx-5 my-3">
                  <h2 className="lh-1 mb-2">Account Details</h2>
                  <div className="row g-5">
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Your Name"
                          value={profileData.name}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                      <div className="mt-4">
                        <label>Phone No.</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Phone No."
                          value={profileData.mobile}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                      <div className="mt-3">
                        <label>What is your occupation?</label>
                        <select
                          className="form-control mt-1"
                          name="occupation"
                          value={profileData.occupation}
                          onChange={handleInputChange}
                        >
                          <option>Producer</option>
                          <option>Director</option>
                          <option>Director of photography (D.O.P)</option>
                          <option>Writer</option>
                          <option>Line Producer</option>
                        </select>
                      </div>
                      <div className="mt-3">
                        <label>Referral Code (Optional)</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Referral Code"
                          name="referralCode"
                          value={profileData.referralCode}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>Have you done any project before this?</label>
                        <div className="mt-2">
                          {[
                            "Film/Movie",
                            "Web Series",
                            "Web Film",
                            "Serial",
                            "Short Film",
                            "Documentary",
                            "All of the above",
                            "None of above",
                          ].map((item) => (
                            <div className="form-check" key={item}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item}
                                checked={profileData.participated.includes(
                                  item
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "participated")
                                }
                              />
                              <label className="form-check-label">{item}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7">
                    <hr />
                  </div>
                  <h2 className="lh-1 mt-7 mb-2">Payment Details</h2>
                  <div className="row g-5">
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>Account Number</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Account Number"
                          name="accountNumber"
                          value={profileData.accountNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Account Number"
                          name="ifscCode"
                          value={profileData.ifscCode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Bank Name"
                          name="bankName"
                          value={profileData.bankName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>A/C Holder Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="A/C Holder Name"
                          name="accountHolderName"
                          value={profileData.accountHolderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>Branch</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Branch"
                          name="branch"
                          value={profileData.branch}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={updateProfile}
                      class="btn btn-warning btn-lg mt-4 px-5 rounded-3 fs-5 mt-8 text-white"
                    >
                      <span className="mx-4">Update Account</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
