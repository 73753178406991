import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateData, setUpdateData] = useState({
    ottUpdates: "",
    ottRemarks: "",
  });

  // Fetch projects from the API when the component loads
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/projects/ott",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ottToken")}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setProjects(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
          setProjects([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setProjects([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Update profile data
  const updateProject = async (projectId) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `https://localhost:8501/api/v1/projects/${projectId}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ottToken")}`,
          },
        }
      );

      // Update the local projects state with the updated project data
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === projectId ? { ...project, ...updateData } : project
        )
      );

      setLoading(false);
    } catch (err) {
      setError("Failed to update profile");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Header />

      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span className="fs-5 px-5 py-6 mb-1 rounded-pill text-center text-black border border-secondary bg-white cursor-pointer">
              Pending (2)
            </span>
            <span className="fs-5 px-5 py-6 rounded-pill text-center text-black border bg-white border-secondary cursor-pointer">
              Approved (0)
            </span>
          </div>
          <span className="fs-5 px-5 py-6 rounded-pill text-center text-black border bg-white border-secondary cursor-pointer">
            Rejected (0)
          </span>
        </div>
      </div>

      <section className="pb-7 mt-5 mb-3">
        <div className="container">
          {loading ? (
            <div class="spinner-border" role="status"></div>
          ) : (
            <div class="row g-2">
              {projects.map((project) => (
                <div class="col-lg-4 col-12">
                  <div className="card rounded-4 card-bordered card-lift">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjqcgvG8llUk12xECDkIzfCCazEYxzJJ3DaqAV7rLv5BUImIMZGwUnuzM8R5bJeVMz1qR9b5C4w1Cy7QTRy0AeSJHqsb29FeooMxxnFrmE_zZdEMCa_Ye6FeMn79UuDFtKm7RlTN-DN5d5a/s1600/shubh-mangal-zyada-saavdhan-movie-star-cast-release-date-poster.jpg"
                          alt="mentor 1"
                          className="w-100 rounded-top-4 object-fit-cover object-position-top"
                          height="200"
                        />
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column justify-content-between p-4 h-100">
                          <div>
                            <div className="d-flex flex-column">
                              <div>
                                <span
                                  class={`badge px-4 ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <h3 className="mb-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${project._id}`}
                                    className="text-reset cursor-pointer"
                                  >
                                    {project.projectName}
                                  </a>
                                </h3>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-patch-check-fill text-success"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                  </svg>
                                </span>
                              </div>
                              <p className="m-0 text-capitalize">
                                {project.category}
                              </p>
                            </div>
                            <div className="mt-3 mb-4">
                              <div class="mb-2 mb-md-0">
                                <span class="me-4">
                                  <i class="bi bi-camera-reels"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.contentType}
                                  </span>
                                </span>
                                <span class="me-2">
                                  <i class="bi bi-chat-square-quote"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.language}
                                  </span>
                                </span>
                                <div>
                                  <i class="bi bi-calendar-week me-1"></i>{" "}
                                  <span className="text-uppercase">
                                    {new Date(project.createdAt).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="progress mb-3" role="progressbar">
                              <div class="progress-bar w-15 rounded-pill"></div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <span>Goal</span>
                                <div className="d-flex flex-row gap-1 align-items-center">
                                  <h4 className="mb-0">
                                    {new Intl.NumberFormat("en-IN").format(
                                      project.tentativeBudget
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${project._id}`}
                                  className="btn btn-outline-primary px-4 rounded-3"
                                >
                                  More Details
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id={project._id}
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content rounded-4">
                        <div className="end-0 position-absolute z-1">
                          <button
                            type="button"
                            class="border-0 bg-transparent m-n3"
                            data-bs-dismiss="modal"
                          >
                            <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div className="card-body px-lg-6 px-4 py-3">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <span
                                  class={`badge ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                                <h3 class="mb-1 fs-4">
                                  <span class="text-inherit text-capitalize">
                                    {project.projectName}
                                  </span>
                                </h3>
                                <div>
                                  <span className="text-capitalize">
                                    {project.category}
                                  </span>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.1 Content Type Selection
                                  </p>
                                  <ul>
                                    <li>
                                      Type of content -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentType}
                                      </span>
                                    </li>
                                    <li>
                                      Language -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.language}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.2 Project Details
                                  </p>
                                  <ul>
                                    <li>
                                      Rights Buyer -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.buyer}
                                      </span>
                                    </li>
                                    <li>
                                      Project Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.projectName}
                                      </span>
                                    </li>
                                    <li>
                                      Poster -{" "}
                                      <a
                                        href={project.poster}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Poster
                                      </a>
                                    </li>
                                    <li>
                                      Contract -{" "}
                                      <a
                                        href={project.contract}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Contract
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <img
                                  src="https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/dharmaveer-2-et00403175-1719817449.jpg"
                                  alt=""
                                  class="icon-shape border rounded-4 object-fit-cover w-100"
                                  height="350"
                                />
                              </div>
                            </div>
                            <div className="mt-5">
                              <div className="mt-4">
                                <p className="text-dark">
                                  1.3 Multiple Contracts Option
                                </p>
                                <ul>
                                  <li>
                                    All Contracts -{" "}
                                    <a
                                      href={project.additionalContracts}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View All Contracts
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="mt-4">
                                <p className="text-dark">
                                  1.4 New Project Submission
                                </p>
                                <ul>
                                  <li>
                                    Synopsis / One-Line Story -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.synopsis}
                                    </span>
                                  </li>
                                  <li>
                                    Story -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.story}
                                    </span>
                                  </li>
                                  <li>
                                    Production House Name -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.productionHouse}
                                    </span>
                                  </li>
                                  <li>
                                    Producer -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.producer}
                                    </span>
                                  </li>
                                  <li>
                                    Director -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.director}
                                    </span>
                                  </li>
                                  <li>
                                    Creative Director -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.creativeDirector}
                                    </span>
                                  </li>
                                  <li>
                                    Writer -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.writer}
                                    </span>
                                  </li>
                                  <li>
                                    Director of Photography (DOP) -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.dop}
                                    </span>
                                  </li>
                                  <li>
                                    Project PPT Look and Feel -{" "}
                                    <a
                                      href={project.projectPPT}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View PPT
                                    </a>
                                  </li>
                                  <li>
                                    Story Narration (Screenplay with Dialogue) -{" "}
                                    <a
                                      href={project.screenplay}
                                      target="_blank"
                                      className="text-danger"
                                    >
                                      View File
                                    </a>
                                  </li>
                                  <li>
                                    Casting -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.casting}
                                    </span>
                                  </li>
                                  <li>
                                    Tentative Budget (INR) -{" "}
                                    <span className="text-danger text-capitalize">
                                      {project.tentativeBudget}
                                    </span>
                                  </li>
                                  <li>
                                    Budget Sheet -{" "}
                                    <span className="text-danger">
                                      View Budget Sheet
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="mt-5">
                              <h3>OTT Updates</h3>
                              <span
                                class={`badge ${
                                  project.ottUpdates === "approved"
                                    ? "bg-success-soft"
                                    : "bg-danger-soft"
                                } mb-2 text-capitalize`}
                              >
                                {project.ottUpdates}
                              </span>
                            </div>
                            <div className="col-8">
                              <div className="mt-4">
                                <label>Add Remark</label>
                                <textarea
                                  type="text"
                                  rows="5"
                                  className="form-control mt-1"
                                  placeholder="Remark"
                                  name="ottRemarks"
                                  onChange={handleInputChange}
                                ></textarea>
                              </div>
                              <div className="mt-4">
                                <label>Update Status</label>
                                <select
                                  className="form-control mt-1"
                                  name="ottUpdates"
                                  onChange={handleInputChange}
                                >
                                  <option>Need Clarification</option>
                                  <option value="approved">Approved</option>
                                  <option value="rejected">Rejected</option>
                                </select>
                              </div>
                              <div>
                                <a
                                  onClick={() => updateProject(project._id)}
                                  class="btn btn-warning btn-lg mt-4 px-5 rounded-3 fs-5 mt-3 text-white"
                                  data-bs-dismiss="modal"
                                >
                                  <span className="mx-4">Update</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Projects;
