import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";

function Unreleased() {
  const [ott, setOtt] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ready, setReady] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readyToRelease, setReadyToRelease] = useState({
    contentType: "",
    language: "",
    projectName: "",
    poster: "",
    contract: "",
    contentDescription: "",
    trailer: "",
    productionHouse: [],
    producerOrInvestor: "",
    director: "",
    creativeDirector: "",
    writer: "",
    dop: "",
    contentFiles: "",
    castCrewDetails: "",
    socialMediaLinks: {},
    castBitRelated: "",
    clipCut: "",
    nocFiles: "",
    budget: 0,
    ottId: "",
  });

  const [socialMediaLinks, setSocialMediaLinks] = useState([
    { platform: "", link: "" },
  ]);
  const availablePlatforms = ["Facebook", "Instagram", "Twitter", "LinkedIn"];

  const handleSocialMediaChange = (index, field, value) => {
    const updatedLinks = [...socialMediaLinks];
    updatedLinks[index][field] = value;
    setSocialMediaLinks(updatedLinks);
    setReadyToRelease((prev) => ({
      ...prev,
      socialMediaLinks: Object.fromEntries(
        updatedLinks
          .filter(({ platform, link }) => platform && link)
          .map(({ platform, link }) => [platform, link])
      ),
    }));
  };

  const handleAddLink = () => {
    setSocialMediaLinks([...socialMediaLinks, { platform: "", link: "" }]);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index);
    setSocialMediaLinks(updatedLinks);
    setReadyToRelease((prev) => ({
      ...prev,
      socialMediaLinks: Object.fromEntries(
        updatedLinks
          .filter(({ platform, link }) => platform && link)
          .map(({ platform, link }) => [platform, link])
      ),
    }));
  };

  const getUnusedPlatforms = (currentPlatform) => {
    return availablePlatforms.filter(
      (platform) =>
        !socialMediaLinks.some(
          (link) =>
            link.platform === platform && link.platform !== currentPlatform
        )
    );
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setReadyToRelease((prevProject) => ({
      ...prevProject,
      [name]: name === "budget" ? parseFloat(value) || 0 : value,
    }));
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setReadyToRelease((prevProject) => ({
      ...prevProject,
      [name]: files.length > 1 ? [...files] : files[0],
    }));
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `https://fundoramaa.cloud/api/v1/ready-to-release/user/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setReady(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Handle adding a new project
  const handleReadyToRelease = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();

    // Append non-file fields
    formData.append("contentType", readyToRelease.contentType);
    formData.append("language", readyToRelease.language);
    formData.append("projectName", readyToRelease.projectName);
    formData.append("contentDescription", readyToRelease.contentDescription);
    formData.append("productionHouse", readyToRelease.productionHouse);
    formData.append("producerOrInvestor", readyToRelease.producerOrInvestor);
    formData.append("director", readyToRelease.director);
    formData.append("creativeDirector", readyToRelease.creativeDirector);
    formData.append("writer", readyToRelease.writer);
    formData.append("dop", readyToRelease.dop);
    formData.append(
      "socialMediaLinks",
      JSON.stringify(readyToRelease.socialMediaLinks)
    );
    formData.append("budget", readyToRelease.budget);
    formData.append("ottId", readyToRelease.ottId);

    // Append files to FormData if available
    if (readyToRelease.poster) {
      formData.append("poster", readyToRelease.poster);
    }
    if (readyToRelease.contract) {
      formData.append("contract", readyToRelease.contract);
    }
    if (readyToRelease.trailer) {
      formData.append("trailer", readyToRelease.trailer);
    }
    if (readyToRelease.contentFiles) {
      formData.append("contentFiles", readyToRelease.contentFiles);
    }
    if (readyToRelease.castCrewDetails) {
      formData.append("castCrewDetails", readyToRelease.castCrewDetails);
    }
    if (readyToRelease.castBitRelated) {
      formData.append("castBitRelated", readyToRelease.castBitRelated);
    }
    if (readyToRelease.clipCut) {
      formData.append("clipCut", readyToRelease.clipCut);
    }
    if (readyToRelease.nocFiles) {
      formData.append("nocFiles", readyToRelease.nocFiles);
    }

    // Log the FormData entries to inspect them
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await axios.post(
        "https://fundoramaa.cloud/api/v1/ready-to-release/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.data) {
        setReady((prevProjects) => [...prevProjects, response.data.data]);
        resetNewProject();
        setIsSubmitting(false);
        const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
      } else {
        console.error("Unexpected response format:", response.data);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error adding project:", error);
      setIsSubmitting(false);
    }
  };

  const resetNewProject = () => {
    setReadyToRelease({
      contentType: "",
      language: "",
      projectName: "",
      poster: "",
      contract: "",
      contentDescription: "",
      trailer: "",
      productionHouse: [],
      producerOrInvestor: "",
      director: "",
      creativeDirector: "",
      writer: "",
      dop: "",
      contentFiles: "",
      castCrewDetails: "",
      socialMediaLinks: {},
      castBitRelated: "",
      clipCut: "",
      nocFiles: "",
      budget: 0,
      ottId: "",
    });
  };

  // Fetch OTT from the API
  useEffect(() => {
    const fetchOtt = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/ott/"
        );
        if (response.data?.data && Array.isArray(response.data.data)) {
          setOtt(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching otts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOtt();
  }, []);

  return (
    <>
      <Header />

      <div class="modal fade" id="addModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content rounded-4">
            <div className="end-0 position-absolute z-1">
              <button
                type="button"
                class="border-0 bg-transparent m-n3"
                data-bs-dismiss="modal"
              >
                <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleReadyToRelease}>
                <div className="card-body px-lg-6 px-4 py-3">
                  <h2 className="lh-1 mb-4">Add Details</h2>
                  <div>
                    <div className="mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.1 Content Type Selection
                      </p>
                    </div>
                    <div>
                      <label>Select your type of content</label>
                      <select
                        className="form-control mt-1"
                        name="contentType"
                        value={readyToRelease.contentType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="tvshow">TV Show</option>
                        <option value="webseries">Web Series</option>
                        <option value="movie">Movie</option>
                        <option value="shortfilm">Short Film</option>
                        <option value="regional">Regional</option>
                      </select>
                    </div>
                    <div className="mt-3">
                      <label>Select language</label>
                      <select
                        className="form-control mt-1"
                        name="language"
                        value={readyToRelease.language}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="hindi">Hindi</option>
                        <option value="bengali">Bengali</option>
                        <option value="marathi">Marathi</option>
                        <option value="telugu">Telugu</option>
                        <option value="tamil">Tamil</option>
                        <option value="gujarati">Gujarati</option>
                        <option value="bhojpuri">Bhojpuri</option>
                        <option value="rajasthani">Rajasthani</option>
                        <option value="chhattisgarhi">Chhattisgarhi</option>
                        <option value="magahi">Magahi</option>
                        <option value="haryanvi">Haryanvi</option>
                        <option value="khortha/khotta">Khortha/Khotta</option>
                        <option value="marwari">Marwari</option>
                        <option value="bundeli/bundel khandi">
                          Bundeli/Bundel khandi
                        </option>
                        <option value="malvi">Malvi</option>
                        <option value="sadan/sadri">Sadan/Sadri</option>
                        <option value="mewari">Mewari</option>
                        <option value="awadhi">Awadhi</option>
                        <option value="lamani/lambadi">Lamani/Lambadi</option>
                        <option value="konkani">Konkani</option>
                        <option value="kurukh/oraon">Kurukh/Oraon</option>
                        <option value="tulu">Tulu</option>
                        <option value="manipuri">Manipuri</option>
                        <option value="sindhi">Sindhi</option>
                        <option value="pahari">Pahari</option>
                        <option value="hara/harauti">Hara/Harauti</option>
                        <option value="bagheli/baghel khandi">
                          Bagheli/Baghel Khandi
                        </option>
                        <option value="garhwali">Garhwali</option>
                        <option value="nimadi">Nimadi</option>
                        <option value="surjapuri">Surjapuri</option>
                        <option value="kumauni">Kumauni</option>
                        <option value="surgujia">Surgujia</option>
                        <option value="banjari">Banjari</option>
                        <option value="brajbhasha">Brajbhasha</option>
                        <option value="dhundhari">Dhundhari</option>
                        <option value="gojri/gujjari/gujar">
                          Gojri/Gujjari/Gujar
                        </option>
                        <option value="kangri">Kangri</option>
                        <option value="ahirani">Ahirani</option>
                        <option value="sambalpuri">Sambalpuri</option>
                        <option value="bagri">Bagri</option>
                        <option value="kachchhi">Kachchhi</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.2 Project Details
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Enter your project name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="projectName"
                        value={readyToRelease.projectName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Upload Poster</label>
                      <input
                        type="file"
                        name="poster"
                        onChange={handleFileChange}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="mt-2">
                      <label>Upload Contract</label>
                      <input
                        type="file"
                        name="contract"
                        onChange={handleFileChange}
                        className="form-control mt-1"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        1.3 Form Fields for Content Submission
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>Content Descriptions</label>
                      <textarea
                        rows="5"
                        className="form-control mt-1"
                        name="contentDescription"
                        value={readyToRelease.contentDescription}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <label>Upload Trailer</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="trailer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Production House Name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="productionHouse"
                        value={readyToRelease.productionHouse}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Producer / Investor</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="producerOrInvestor"
                        value={readyToRelease.producerOrInvestor}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="director"
                        value={readyToRelease.director}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Creative Director</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="creativeDirector"
                        value={readyToRelease.creativeDirector}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Writer</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="writer"
                        value={readyToRelease.writer}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Director of Photography (DOP)</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder=""
                        name="dop"
                        value={readyToRelease.dop}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Content Files</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="contentFiles"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Cast & Crew Details</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="castCrewDetails"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Social Media Links</label>
                      {socialMediaLinks.map((link, index) => (
                        <div key={index} className="d-flex mb-2">
                          <select
                            className="form-select me-2 mt-1"
                            value={link.platform}
                            onChange={(e) =>
                              handleSocialMediaChange(
                                index,
                                "platform",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Platform</option>
                            {getUnusedPlatforms(link.platform).map(
                              (platform) => (
                                <option key={platform} value={platform}>
                                  {platform}
                                </option>
                              )
                            )}
                          </select>
                          <input
                            type="url"
                            placeholder="Enter link"
                            className="form-control"
                            value={link.link}
                            onChange={(e) =>
                              handleSocialMediaChange(
                                index,
                                "link",
                                e.target.value
                              )
                            }
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-warning ms-2 rounded-circle"
                              onClick={() => handleRemoveLink(index)}
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm px-5 rounded-pill mt-2 mb-2"
                        onClick={handleAddLink}
                        disabled={
                          socialMediaLinks.length >= availablePlatforms.length
                        }
                      >
                        Add More
                      </button>
                    </div>
                    <div className="mt-2">
                      <label>Cast Bite Related to Content</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="castBitRelated"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Clip Cut (1-3 Minutes)</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="clipCut"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 mb-3">
                      <p className="text-dark mb-1 bg-light py-2 text-center rounded-pill">
                        Project Declaration
                      </p>
                    </div>
                    <div className="mt-2">
                      <label>NOC file of all artist and all technicians</label>
                      <input
                        type="file"
                        className="form-control mt-1"
                        placeholder=""
                        name="nocFiles"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Budget</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="budget"
                        value={readyToRelease.budget}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label>Select OTT</label>
                      <select
                        className="form-control mt-1"
                        name="ottId"
                        value={readyToRelease.ottId}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        {ott.map((ott) => (
                          <option value={ott._id}>{ott.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg mt-5 w-100 rounded-3 fs-5 text-white"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting ..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="d-none"
                  ></button>
                </div>
              </form>
              <div className="card-footer rounded-4 px-6 pb-4">
                <p className="mb-0 text-center">
                  By submitting you accept our{" "}
                  <a href="#" className="text-inherit fw-semibold">
                    <u>Policies</u>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <a
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            class="btn btn-dark px-4 rounded-3 me-5"
          >
            <span className="mx-2">
              Add Project <i className="bi bi-file-post ms-2"></i>
            </span>
          </a>

          <div className="d-flex flex-column">
            <span className="fs-7 px-5 py-6 mb-1 rounded-pill text-center text-black border border-secondary bg-white cursor-pointer">
              Pending (2)
            </span>
            <span className="fs-7 px-5 py-6 rounded-pill text-center text-black border bg-white border-secondary cursor-pointer">
              Approved (0)
            </span>
          </div>
        </div>
      </div>

      <section className="pb-7 mt-5 mb-3">
        <div className="container">
          {loading ? (
            <div class="spinner-border" role="status"></div>
          ) : (
            <div class="row g-2">
              {ready.map((project) => (
                <div class="col-lg-4 col-12">
                  <div className="card rounded-4 card-bordered card-lift">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          src={project.poster}
                          alt="Poster"
                          className="w-100 rounded-top-4 object-fit-cover object-position-top"
                          height="200"
                        />
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column justify-content-between p-4 h-100">
                          <div>
                            <div className="d-flex flex-column">
                              <div>
                                <span
                                  class={`badge px-4 ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <h3 className="mb-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${project._id}`}
                                    className="text-reset cursor-pointer"
                                  >
                                    {project.projectName}
                                  </a>
                                </h3>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-patch-check-fill text-success"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 mb-4">
                              <div class="mb-2 mb-md-0">
                                <span class="me-4">
                                  <i class="bi bi-camera-reels"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.contentType}
                                  </span>
                                </span>
                                <span class="me-4">
                                  <i class="bi bi-chat-square-quote"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.language}
                                  </span>
                                </span>
                                <br />
                                <span class="me-4">
                                  <i class="bi bi-tv"></i>
                                  <span class="ms-1 text-capitalize">
                                    {project.ottId.name}
                                  </span>
                                </span>
                                <div>
                                  <i class="bi bi-calendar-week me-1"></i>{" "}
                                  <span className="text-uppercase">
                                    {new Date(project.createdAt).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="progress mb-3" role="progressbar">
                              <div class="progress-bar w-15 rounded-pill"></div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <span>Goal</span>
                                <div className="d-flex flex-row gap-1 align-items-center">
                                  <h4 className="mb-0">
                                    {new Intl.NumberFormat("en-IN").format(
                                      project.budget
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${project._id}`}
                                  className="btn btn-outline-primary px-4 rounded-3"
                                >
                                  More Details
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id={project._id}
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content rounded-4">
                        <div className="end-0 position-absolute z-1">
                          <button
                            type="button"
                            class="border-0 bg-transparent m-n3"
                            data-bs-dismiss="modal"
                          >
                            <i className="bi bi-x fs-2 text-white circle-md bg-primary rounded-circle"></i>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div className="card-body px-lg-6 px-4 py-3">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <span
                                  class={`badge ${
                                    project.ottUpdates === "approved"
                                      ? "bg-success-soft"
                                      : "bg-danger-soft"
                                  } mb-2 text-capitalize`}
                                >
                                  {project.ottUpdates}
                                </span>
                                <h3 class="mb-1 fs-4">
                                  <span class="text-inherit text-capitalize">
                                    {project.projectName}
                                  </span>
                                </h3>
                                <div>
                                  <span className="text-dark">
                                    {project.ottId.name}
                                  </span>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.1 Content Type Selection
                                  </p>
                                  <ul>
                                    <li>
                                      Type of content -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentType}
                                      </span>
                                    </li>
                                    <li>
                                      Language -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.language}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.2 Project Details
                                  </p>
                                  <ul>
                                    <li>
                                      Project Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.projectName}
                                      </span>
                                    </li>
                                    <li>
                                      Poster -{" "}
                                      <a
                                        href={project.poster}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Poster
                                      </a>
                                    </li>
                                    <li>
                                      Contract -{" "}
                                      <a
                                        href={project.contract}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Contract
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="mt-4">
                                  <p className="text-dark">
                                    1.3 Form Fields for Content Submission
                                  </p>
                                  <ul>
                                    <li>
                                      Content Description -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.contentDescription}
                                      </span>
                                    </li>
                                    <li>
                                      Trailer -{" "}
                                      <a
                                        href={project.trailer}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Trailer
                                      </a>
                                    </li>
                                    <li>
                                      Production House Name -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.productionHouse}
                                      </span>
                                    </li>
                                    <li>
                                      Producer / Investor -{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.producerOrInvestor}
                                      </span>
                                    </li>
                                    <li>
                                      Director-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.director}
                                      </span>
                                    </li>
                                    <li>
                                      Creative Director-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.creativeDirector}
                                      </span>
                                    </li>
                                    <li>
                                      Writer-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.writer}
                                      </span>
                                    </li>
                                    <li>
                                      Director of Photography (DOP)-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.dop}
                                      </span>
                                    </li>
                                    <li>
                                      Content Files -{" "}
                                      <a
                                        href={project.contentFiles}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Content Files
                                      </a>
                                    </li>
                                    <li>
                                      Cast & Crew Details-{" "}
                                      <a
                                        href={project.castCrewDetails}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Files
                                      </a>
                                    </li>
                                    <li>
                                      Social Media Links
                                      <br />
                                      <span className="text-dark">
                                        {Object.entries(
                                          project.socialMediaLinks
                                        ).map(([platform, link], index) => (
                                          <span key={index}>
                                            {platform} :{" "}
                                            <a
                                              href={link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {link}
                                            </a>
                                            {index <
                                              Object.entries(
                                                project.socialMediaLinks
                                              ).length -
                                                1 && ", "}
                                          </span>
                                        ))}
                                      </span>
                                    </li>
                                    <li>
                                      Cast Bite Related to Content -{" "}
                                      <a
                                        href={project.castBitRelated}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Cast Bit
                                      </a>
                                    </li>
                                    <li>
                                      Clip Cut (1-3 Minutes) -{" "}
                                      <a
                                        href={project.clipCut}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View Clip Cut
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="mt-4">
                                  <p className="text-dark">
                                    Project Declaration
                                  </p>
                                  <ul>
                                    <li>
                                      NOC file of all artist and all technicians
                                      -{" "}
                                      <a
                                        href={project.nocFiles}
                                        target="_blank"
                                        className="text-danger"
                                      >
                                        View NOC Files
                                      </a>
                                    </li>
                                    <li>
                                      Budget-{" "}
                                      <span className="text-danger text-capitalize">
                                        {project.budget}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <img
                                  src={project.poster}
                                  alt=""
                                  class="icon-shape border rounded-4 object-fit-cover w-100"
                                  height="350"
                                />
                              </div>
                            </div>

                            <div className="mt-5">
                              <h3>OTT Updates</h3>
                              <span
                                class={`badge ${
                                  project.ottUpdates === "approved"
                                    ? "bg-success-soft"
                                    : "bg-danger-soft"
                                } mb-2 text-capitalize`}
                              >
                                {project.ottUpdates}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Unreleased;
