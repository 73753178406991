import { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { Link } from "react-router-dom";

function ContentDashboard() {
  const [projects, setProjects] = useState({
    total: 0,
    newProjects: 0,
    gapFunding: 0,
  });
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState([0]);
  const [content, setContent] = useState([0]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/projects/user/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        if (response.data?.data && Array.isArray(response.data.data)) {
          const projects = response.data.data;

          const newProjects = projects.filter(
            (project) => project.category === "new project"
          );

          const gapFundingProjects = projects.filter(
            (project) => project.category === "gap funding"
          );

          setProjects({
            total: projects.length,
            newProjects: newProjects.length,
            gapFunding: gapFundingProjects.length,
          });
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `https://fundoramaa.cloud/api/v1/ready-to-release/user/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setReady(response.data.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setReady([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setReady([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://fundoramaa.cloud/api/v1/content/user/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setContent(response.data.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setContent([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setContent([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  return (
    <>
      <Header />

      <section>
        <div className="container mt-7">
          <div className="col-lg-6 col-12">
            <div class="row g-2">
              <div class="col-6">
                <div class="card bg-light d-flex justify-content-center h-100">
                  <div class="p-4">
                    <h2 class="fw-bold mb-1 d-flex align-items-center h1 lh-1">
                      {projects.newProjects}
                    </h2>
                    <span>
                      <span className="me-3">1 Pending</span>
                      <span class="badge bg-info mt-1">New Project</span>
                    </span>
                    <div>
                      <Link
                        to="/filmmaker/projects"
                        class="badge border-secondary border border-opacity-25 p-2 fs-6 px-4 mt-3 rounded-pill text-secondary"
                      >
                        Add Project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card bg-light d-flex justify-content-center h-100">
                  <div class="p-4">
                    <h2 class="fw-bold mb-1 d-flex align-items-center h1 lh-1">
                      {projects.gapFunding}
                    </h2>
                    <span>
                      <span className="me-3">1 Pending</span>
                      <span class="badge bg-warning mt-1">GAP Funding</span>
                    </span>
                    <div>
                      <Link
                        to="/filmmaker/projects"
                        class="badge border-secondary border border-opacity-25 p-2 fs-6 px-4 mt-3 rounded-pill text-secondary"
                      >
                        Add Project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card bg-light d-flex justify-content-center h-100">
                  <div class="p-4">
                    <h2 class="fw-bold mb-1 d-flex align-items-center h1 lh-1">
                      {ready}
                    </h2>
                    <span>
                      <span className="me-3">1 Pending</span>
                      <span class="badge bg-danger mt-1">Unreleased</span>
                    </span>
                    <div>
                      <Link
                        to="/filmmaker/unreleased"
                        class="badge border-secondary border border-opacity-25 p-2 fs-6 px-4 mt-3 rounded-pill text-secondary"
                      >
                        Add Project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card bg-light d-flex justify-content-center h-100">
                  <div class="p-4">
                    <h2 class="fw-bold mb-1 d-flex align-items-center h1 lh-1">
                      {content}
                    </h2>
                    <span>
                      <span className="me-3">1 Pending</span>
                      <span class="badge bg-dark mt-1">Released</span>
                    </span>
                    <div>
                      <Link
                        to="/filmmaker/released"
                        class="badge border-secondary border border-opacity-25 p-2 fs-6 px-4 mt-3 rounded-pill text-secondary"
                      >
                        Add Project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContentDashboard;
